<template>
  <div class="auth-apply-box top-padding">
    <top-home title="权限申请处理"></top-home>
    <div class="apply-list-box">
      <div class="read-null" v-if="list.length<=0 && listFinished">
        <img src="@/assets/PersonalCenter/PointsCenter/noDataPng.png" alt width="60%" />
        <div class="null-text">暂无数据</div>
      </div>
      <div class="list-box" v-else>
        <van-list
          v-model="listLoading"
          :finished="listFinished"
          finished-text="已经到底了~"
          @load="listOnLoadFun"
        >
          <div class="list-item" v-for="item in list" :key="item.id" @click="openPopFun(item)">
            <div class="item-icon">
              <van-icon name="user-o" />
            </div>
            <div class="item-content">
              <div class="content-text">{{item.blade_user_name}}</div>
              <div class="content-tip">
                申请状态：
                <span :style="{color:ztDic[item.sq_zt]['color']}">{{ztDic[item.sq_zt]['text']}}</span>
              </div>
              <div class="content-tip">
                申请时间：
                <span>{{item.sq_sj}}</span>
              </div>
            </div>
            <div class="item-arrow">
              <van-icon name="arrow" />
            </div>
          </div>
        </van-list>
      </div>
    </div>
    <div class="apply-pop-box">
      <van-popup v-model="applyPop" position="right" :style="{ width:'90%',height: '100%' }">
        <div class="pop-title">申请信息</div>
        <van-cell-group inset>
          <van-field :value="info.blade_user_name" readonly label="申请人" />
          <van-field autosize type="textarea" :value="info.role_str" readonly label="索取权限" />
          <van-field autosize type="textarea" :value="info.sq_yy" readonly label="申请原因" />
          <van-field
            v-if="ztDic[info.sq_zt]"
            :value="ztDic[info.sq_zt]['text']"
            readonly
            label="申请状态"
          />
          <van-field :value="info.sq_sj" readonly label="申请时间" />
          <van-field v-if="info.sx_sj" :value="info.sx_sj" readonly label="生效时间" />
          <van-field v-if="info.zs_sj" :value="info.zs_sj" readonly label="截止时间" />
        </van-cell-group>
        <div class="pop-button" v-if="isOperation">
          <van-button round type="danger" @click="authApplyFun(false)">拒 绝</van-button>
          <van-button round type="primary" @click="authApplyFun(true)">同 意</van-button>
        </div>
      </van-popup>
    </div>
  </div>
</template>

<script>
import { getComputerTableApi, auditAuthApplyApi } from '@/reuqest/api'
import { mapGetters } from 'vuex'
export default {
  data() {
    return {
      listLoading: false,
      listFinished: false,
      getDataThrottle: false,
      tablePage: {
        pageNo: 1,
        pageSize: 20,
        total: 0,
      },
      list: [],
      ztDic: {
        '-1': {
          text: '拒绝',
          color: 'red',
        },
        0: {
          text: '待授权',
          color: '#232323',
        },
        1: {
          text: '生效中',
          color: '#07C160',
        },
        2: { text: '已失效', color: '#999' },
      },
      applyPop: false,
      info: {},
    }
  },
  computed: {
    ...mapGetters(['bindList']),
    isOperation() {
      if (this.info.sq_zt !== '0') {
        return false
      }
      let data = {}
      this.bindList.forEach((item) => {
        if (item.flag) {
          data = item
        }
      })
      let isAdministrator = data.administratorFlag === 1
      let isAdmin = data.adminFlag === 1
      if (isAdministrator) {
        return true
      } else if (isAdmin && this.info.role_str != '超级管理员') {
        return true
      } else {
        return false
      }
    },
  },
  methods: {
    openPopFun(item) {
      this.info = item
      this.applyPop = true
    },
    authApplyFun(bool) {
      let text = bool ? '确认同意申请？' : '确认拒绝申请？'
      let status = bool ? '1' : '-1'
      this.$dialog
        .confirm({
          title: '提示',
          message: text,
        })
        .then(() => {
          // on confirm
          this.$toast.loading({
            message: '加载中...',
            forbidClick: true,
            duration: 0,
          })
          auditAuthApplyApi({
            id: this.info.id,
            status,
          }).then((res) => {
            this.$toast.clear()
            this.$toast.success('操作成功~')
            this.list = []
            this.tablePage = {
              pageNo: 1,
              pageSize: 20,
              total: 0,
            }
            this.listFinished = false
            this.listLoading = false
            this.listOnLoadFun()
            this.applyPop = false
          })
        })
        .catch(() => {
          // on cancel
        })
    },
    async listOnLoadFun() {
      if (this.getDataThrottle == false) {
        this.getDataThrottle = true
        this.getDataTimer = setTimeout(() => {
          return new Promise((resolve) => {
            let apiData = {
              pageNo: this.tablePage.pageNo,
              pageSize: this.tablePage.pageSize,
              column: 'id',
              order: 'desc',
            }
            getComputerTableApi('1491949047635894274', apiData).then((res) => {
              let data = res.data
              if (data.records) {
                this.list = [...this.list, ...data.records]
                this.tablePage.total = data.total
                this.tablePage.pageNo = this.tablePage.pageNo + 1
              } else {
                this.list = []
                this.tablePage.total = 0
              }
              if (this.list.length >= this.tablePage.total) {
                this.listFinished = true
              } else {
                this.listFinished = false
              }
              this.listLoading = false
              this.getDataThrottle = false
              resolve()
            })
          })
        }, 1000)
      }
    },
  },
}
</script>

<style lang="less" scoped>
.auth-apply-box {
}
.apply-title {
  font-size: 16px;
  background-color: #23a9fe;
  color: #fff;
  padding: 10px 0;
}
.apply-list-box {
  .read-null {
    padding-top: 20px;
    .null-text {
      font-size: 12px;
      color: #999;
    }
  }
}
.list-box {
  padding-bottom: 20px;
  overflow-y: auto;
  height: calc(100vh - 61px);
  .list-item {
    display: flex;
    align-items: center;
    background-color: #fff;
    padding: 10px 20px;
    margin-top: 5px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    .item-icon {
      font-size: 24px;
      color: #23a9fe;
    }
    .item-content {
      flex: 1;
      text-align: left;
      padding-left: 10px;
      .content-text {
        font-size: 16px;
      }
      .content-tip {
        font-size: 12px;
        padding-top: 2px;
        color: #999;
        .tip-num {
          color: red;
        }
      }
    }
    .item-arrow {
      font-size: 16px;
    }
    &:nth-of-type(1) {
      margin-top: 0;
    }
  }
}
.apply-pop-box {
  .pop-title {
    font-size: 16px;
    color: #232323;
    padding: 10px 0;
  }
  .pop-button {
    margin-top: 16px;
    /deep/.van-button {
      padding: 8px 30px;
      &:nth-of-type(1) {
        margin-right: 20px;
      }
    }
  }
}
</style>