<template>
  <div class="meter-list-box" :class="[{'meter-list-box-yhcx':$route.params.id}]">
    <div class="list-item" v-for="item in listData" :key="item.id" @click="openReadFun(item)">
      <div class="item-content">
        <div class="content-text">
          {{item.hm}}
          <span class="text-tip">（{{item.hh}}）（{{item.sdb}}）</span>
        </div>
        <div class="content-other">
          <div class="other-text">
            表号：
            <span class="text">{{item.bh}}</span>
          </div>
          <div class="other-text">
            抄表序号：
            <span class="text">{{item.cbxh}}</span>
          </div>
        </div>
        <div class="content-other">
          <div class="other-text">
            所属手册：
            <span class="text">{{item.cbsc}}</span>
          </div>
          <div class="other-text">
            上期读数：
            <span class="text">{{item.sqds}}</span>
          </div>
        </div>
      </div>
      <div class="item-arrow">
        <van-icon name="arrow" />
      </div>
    </div>
    <div class="list-end">已经到底啦~</div>
    <van-popup
      teleport="body"
      v-model="readItemPop"
      closeable
      position="right"
      :style="{ width:'100%',height: '100%' }"
    >
      <read-item :info="currInfo" :pop="readItemPop" :closeFun="closeFun.bind(this)"></read-item>
    </van-popup>
  </div>
</template>

<script>
import ReadItem from '@/components/WaterMeter/read-item.vue'
export default {
  components: {
    ReadItem,
  },
  props: {
    listData: {
      type: Array,
      default: () => [],
    },
    refreshFun: Function,
  },

  data() {
    return {
      readItemPop: false,
      currInfo: {},
    }
  },
  methods: {
    openReadFun(data) {
      this.currInfo = data
      this.readItemPop = true
    },
    closeFun() {
      this.readItemPop = false
      this.refreshFun()
    },
  },
}
</script>

<style lang="less" scoped>
.meter-list-box {
  overflow-y: auto;
  height: calc(100vh - 99px);
  .list-item {
    background-color: #fff;
    padding: 15px 20px;
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    .item-content {
      flex: 1;
    }
    .content-text {
      text-align: left;
      font-size: 14px;
      color: #232323;
    }
    .content-other {
      display: flex;
      align-items: center;
      padding-top: 5px;
      font-size: 12px;
      color: #666;
      .other-text {
        flex: 1;
        text-align: left;
        .text {
          color: #232323;
        }
      }
    }
    .item-arrow {
      font-style: 16px;
    }
  }
  .list-end {
    padding: 10px 0;
    color: #999;
    font-size: 12px;
  }
}
.meter-list-box-yhcx {
  height: calc(100vh - 40px);
}
</style>