<template>
  <div class="water-list-box top-padding">
    <top-home :title="currManual"></top-home>
    <div class="water-list">
      <div class="read-null" v-if="listData.length<=0">
        <img src="@/assets/PersonalCenter/PointsCenter/noDataPng.png" alt width="60%" />
        <div class="null-text">暂无需要抄表的用户</div>
      </div>
      <div class="list-box" v-else>
        <meter-list :listData="listData" :refreshFun="refreshFun.bind(this)"></meter-list>
      </div>
    </div>
  </div>
</template>

<script>
import { getCbyWaterListApi } from '@/reuqest/api'
import MeterList from '@/components/WaterMeter/meter-list.vue'
import { mapGetters } from 'vuex'
export default {
  components: {
    MeterList,
  },
  data() {
    return {
      cbscId: '',
      listData: [],
    }
  },
  computed: {
    ...mapGetters(['currManual']),
  },
  created() {
    this.getListDataFun()
  },
  methods: {
    getListDataFun() {
      this.$toast.loading({
        message: '加载中...',
        forbidClick: true,
        duration: 0,
      })
      this.cbscId = this.$route.params.id
      getCbyWaterListApi({
        cbscId: this.cbscId,
      }).then((res) => {
        this.listData = res.data
        this.$toast.clear()
      })
    },
    refreshFun() {
      this.getListDataFun()
    },
  },
}
</script>

<style lang="less" scoped>
.water-list-box {
  background-color: #f1f0f5;
  min-height: 100vh;
  .water-title {
    font-size: 16px;
    background-color: #23a9fe;
    color: #fff;
    padding: 10px 0;
  }
  .read-null {
    padding-top: 30px;
    color: #999;
  }
}
</style>