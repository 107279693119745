<template>
  <div class="read-item-box">
    <div class="read-title">{{info.hm}}（{{info.sdb}}）</div>
    <div class="read-info-list">
      <van-form @submit="onSubmit">
        <van-cell-group inset>
          <van-field :value="info.hh" readonly label="户号" />
          <van-field
            v-if="info.yhzz"
            autosize
            type="textarea"
            :value="info.yhzz"
            readonly
            label="户号"
          />
          <van-field :value="info.bh" readonly label="表号" />
          <van-field :value="info.cbsc" readonly label="抄表手册" />
          <van-field :value="info.cbzqTitle" readonly label="抄表周期" />
          <van-field v-if="info.cb_rq" :value="info.cb_rq | timeFilter" readonly label="上期抄表时间" />
          <van-field :value="info.sqds" readonly label="上期读数" />
          <van-field
            v-model="bqds"
            @blur="bqdsUpdateFun"
            type="number"
            required
            label="本期读数"
            placeholder="请输入本期读数"
          />
        </van-cell-group>
        <div style="margin: 16px;">
          <van-button round block type="primary" native-type="submit">确定抄表</van-button>
        </div>
      </van-form>
    </div>
  </div>
</template>

<script>
import { cbyMeterReadingApi } from '@/reuqest/api'
export default {
  props: {
    info: {
      type: Object,
      default: () => {},
    },
    pop: Boolean,
    closeFun: Function,
  },
  filters: {
    timeFilter(value) {
      if (value.indexOf(' ') != -1) {
        return value.split(' ')[0]
      }
      return value
    },
  },
  watch: {
    pop(value) {
      if (value) {
        this.bqds = ''
      }
    },
  },
  data() {
    return {
      bqds: '',
    }
  },
  methods: {
    onSubmit() {
      if (this.bqds === '') {
        this.$toast('请输入本期读数')
        return false
      }
      if (this.bqds < this.info.sqds) {
        this.$toast('本期读数不能小于上期读数')
        return false
      }
      this.$dialog
        .confirm({
          title: '抄表',
          message: `本期读数：${this.bqds}，确认填写无误？`,
        })
        .then(() => {
          // on confirm
          this.$toast.loading({
            message: '加载中...',
            forbidClick: true,
            duration: 0,
          })
          cbyMeterReadingApi({
            bqds: this.bqds - 0,
            sbId: this.info.id,
          }).then((res) => {
            this.$toast.clear()
            this.$toast.success('抄表成功~')
            setTimeout(() => {
              this.closeFun()
            }, 1000)
          })
        })
        .catch(() => {
          // on cancel
        })
    },
    bqdsUpdateFun() {
      let index = (this.bqds + '').indexOf('.')
      if (index == -1) {
        return false
      }
      if (index + 2 < index.length) {
        return false
      }
      this.bqds = this.bqds.substring(0, index + 2) - 0
    },
  },
}
</script>

<style lang="less" scoped>
.read-item-box {
  background-color: #f1f0f5;
  height: 100%;
  .read-title {
    box-sizing: border-box;
    width: 100%;
    font-size: 16px;
    background-color: #fff;
    height: 51px;
    padding: 16px 20px 5px 20px;
    text-align: center;
    border-bottom: 1px solid #f1f1f1;
  }
}
.read-info-list {
  margin-top: 5px;
  /deep/.van-form {
    .van-cell-group {
      margin: 0;
    }
  }
}
</style>